import React, { Component } from "react";
import sanitizeHtml from "./SanitizeHtml";
import md from "./RenderMarkdown";

class Marked extends Component {
  render() {
    let value = this.props.value;

    let str = sanitizeHtml(md.render(value || ""));
    str = str.replaceAll('src="', 'src="' + process.env.REACT_APP_API);
    return (
      <div dangerouslySetInnerHTML={{ __html: str }} />
      // <ReactMarkdown
      //   remarkPlugins={[
      //     gfm,
      //     remarkSubSuper,
      //     [
      //       html,
      //       {
      //         handlers: [
      //           {
      //             type: "sup",
      //             data: {
      //               hName: "i",
      //               hProperties: { className: "foo" },
      //               hChildren: [{ type: "text", value: "bar" }],
      //             },
      //             children: [{ type: "text", value: "baz" }],
      //           },
      //         ],
      //       },
      //     ],
      //   ]}
      //   transformImageUri={(src) => process.env.REACT_APP_API + src}
      //   children={value}
      // />
      // <MarkdownPreview
      //   markedOptions={{ baseUrl: "https://rceurope.janpetrik.cz//api//" }}
      //   {...this.props}
      // />
    );
  }
}

export default Marked;
