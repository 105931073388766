import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Marked from "../../../Utils/Marked";
import "./LeftTextRightImages.scss";

class LeftTextRightImages extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    let Images = [];

    this.props.images.forEach((item, index) => {
      Images.push(
        <div
          key={index}
          className="LeftTextRightImagesImage"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API + item.url})`,
            height: 100 / this.props.images.length + "%",
          }}
        ></div>
      );
    });

    return (
      <div className="LeftTextRightImagesWrapper maxWidth">
        <div className="LeftTextRightImagesText">
          <Marked value={this.props.text} />
        </div>
        <div className="LeftTextRightImagesImages">{Images}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(LeftTextRightImages));
