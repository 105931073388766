import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./News.scss";
import removeMarkdown from "markdown-to-text";

var dateFormat = require("dateformat");

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: 0,
    };
    //this.contentRef = React.createRef();
  }

  async componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  componentDidUpdate() {
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = (evt) => {
    //const contentRef = this.contentRef.current;
    let width = window.innerWidth;

    if (width < 1000 && this.state.columns !== 2) {
      this.setState({
        columns: 2,
      });
    }
    if (width >= 1000 && this.state.columns !== 3) {
      this.setState({
        columns: 3,
      });
    }
  };

  render() {
    let News = [];
    for (
      let i = 0;
      i < this.state.columns && i < this.props.news.data.length;
      i++
    ) {
      let item = this.props.news.data[i];

      const text =
        item.text.length > 250
          ? removeMarkdown(item.text).slice(0, 250) + ""
          : removeMarkdown(item.text);
      News.push(
        <Link
          key={i}
          className="NewsModuleItem"
          to={
            this.props.locale === "cs-CZ"
              ? "/aktuality/" + item.id
              : "/news/" + item.id
          }
        >
          <div
            className="NewsModuleItemImage"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_API +
                (item?.image?.formats?.medium !== undefined
                  ? item.image.formats.medium.url
                  : item.image.url)
              })`,
            }}
          />
          <div className="NewsModuleTextContainer">
            <div className="NewsModuleTextTitle">{item.title}</div>
            <div className="NewsModuleTextDate">
              {dateFormat(item.date, "dd.m. yyyy")}
            </div>
            <div className="NewsModuleTextText">{text}</div>
          </div>
        </Link>
      );
    }
    return (
      <div className="NewsModule maxWidth">
        <div className="NewsModuleHeader">
          <div className="redTitle">{this.props.name}</div>
          {typeof this.props.link !== "undefined" &&
          this.props.link !== null &&
          typeof this.props.link.id !== "undefined" &&
          this.props.nav.map[this.props.link.id] ? (
            <Link
              className="NewsModuleLink"
              to={this.props.nav.map[this.props.link.id]}
            >
              {this.props.allNewsLinkText}
              <FontAwesomeIcon
                icon={faArrowRight}
                className="NewsModuleLinkArrow"
              />
            </Link>
          ) : null}
        </div>
        <div className="NewsModuleItemsContainer">{News}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    news: state.news,
    locale: state.locale,
    nav: state.nav,
  };
}

export default withRouter(connect(mapStateToProps)(News));
