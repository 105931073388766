import React, { Component } from "react";
import Modal from "react-modal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ModalC extends Component {
  render() {
    return (
      <Modal
        ariaHideApp={false}
        {...this.props}
        style={{
          overlay: {
            zIndex: 1000,
            position: "fixed",
          },
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "fixed",
              fontSize: "30px",
              padding: "20px",
              cursor: "pointer",
              zIndex: 10000,
              color: "#707070",
            }}
            onClick={this.props.onRequestClose}
          >
            <div
              style={{
                backgroundColor: "rgba(255,255,255,0.5)",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="navCloseIcon" />
            </div>
          </div>
          <div className="Modal">{this.props.children}</div>
        </div>
      </Modal>
    );
  }
}

export default ModalC;
