import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./Portfolio.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import ModalBuildings from "../../../Utils/ModalBuildings";
var _ = require("lodash");

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      modal: false,
    };
  }

  async componentDidMount() {}

  select = (id) => {
    if (this.state.selected === id) {
      this.setState({ selected: false });
    } else {
      this.setState({ selected: id });
    }
  };

  modal = (modal) => {
    this.setState({
      modal: modal,
    });
  };

  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  render() {
    let Buildings = [];

    if (this.state.selected !== false) {
      this.props.buildings.buildings.forEach((item, index) => {
        if (
          item.development !== null &&
          this.state.selected === item.development.id &&
          item.development.hideInPortfolio === false
        ) {
          Buildings.push(item);
        }
      });
    } else {
      this.props.buildings.buildings.forEach((item, index) => {
        if (
          item.development === null ||
          (item.development !== null &&
            item.development.hideInPortfolio === false)
        ) {
          Buildings.push(item);
        }
      });
    }

    Buildings = _.orderBy(Buildings, ["sort"], ["asc"]);

    return (
      <div className="PortfolioContainer">
        <ModalBuildings data={this.state.modal} closeModal={this.closeModal} />
        <div className="PortfolioWrapper maxWidth">
          <div className="redTitle">{this.props.title}</div>
          <div className="PortfolioFilterText">{this.props.filterText}</div>
          <div className="PortfolioFilters">
            {this.props.buildings.development.map((item, index) => {
              if (item.hideInPortfolio === true) {
                return null;
              }
              return (
                <div
                  key={index}
                  onClick={() => this.select(item.id)}
                  className={
                    item.id === this.state.selected
                      ? "PortfolioDevelopment PortfolioDevelopmentSelected"
                      : "PortfolioDevelopment"
                  }
                >
                  <div className="PortfolioTitle">
                    {item.title}
                    <img
                      className="PortfolioImage"
                      src={process.env.REACT_APP_API + item.logo.url}
                      alt=""
                    />
                  </div>
                  <div>{item.shortDescription}</div>
                </div>
              );
            })}
          </div>
          <div className="PortfolioBuildings">
            {Buildings.map((item, index) => {
              return (
                <div
                  onClick={() => this.modal(item)}
                  key={index}
                  className="PortfolioBuilding"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_API +
                      (item.image.formats.medium !== undefined
                        ? item.image.formats.medium.url
                        : item.image.url)
                    })`,
                  }}
                >
                  <div className="PortfolioBuildingSqure">
                    <div className="PortfolioBuildingFade"></div>

                    <div className="PortfolioBuildingTitle">{item.title}</div>
                    {/* {item.forRentNow ? (
                      <div className="PortfolioBuildingRent">
                        {this.props.rentText}
                      </div>
                    ) : null} */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    buildings: state.buildings,
  };
}

export default withRouter(connect(mapStateToProps)(Portfolio));
