import * as actionType from "../Actions/types";
var _ = require("lodash");

const tag = (state = [], action) => {
  switch (action.type) {
    case actionType.SET_TAG:
      let newState = _.xor(state, [action.tag]);
      return newState;
    case actionType.SET_LOCALE:
      return [];
    default:
      return state;
  }
};

export default tag;
