import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Marked from "../../../Utils/Marked";
import "./BuildingCategories.scss";
import ModalBuildings from "../../../Utils/ModalBuildings";
import BrokerCard from "../../../Components/Modules/Content/BrokerCard";
import { v4 as uuid } from "uuid";

class BuildingCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBuilding: false,
      moreRent: false,
    };
  }

  async componentDidMount() {}

  getBuilding = (building, key, buildings) => {
    let image = building.image;
    if (building.gallery !== null) {
      building.gallery.forEach((item) => {
        if (item.caption === building.currentCategory) {
          image = item;
        }
      });
    }

    let text = "";
    building.RentShortText.forEach((item) => {
      if (building.currentCategory === item.building_category.category) {
        text = item.text;
      }
    });

    return (
      <div key={key} className="BuildingCategoriesSubWrapper">
        <div
          className="BuildingCategoriesSubBuildingImage"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API + image.url})`,
          }}
        />
        <div className="BuildingCategoriesSubBuildingInfoWrapper">
          <div className="BuildingCategoriesSubBuildingTitle">
            {building.title}
          </div>
          <div className="BuildingCategoriesSubBuildingDesc">{text}</div>
          <div
            onClick={() => this.modalBuilding(building, buildings, key)}
            className="BuildingCategoriesSubBuildingShowMoreText"
          >
            Detail
          </div>
        </div>
      </div>
    );
  };

  getBuildingsArray = (buildings, exclude = false) => {
    let o = [];
    buildings.forEach((building, index2) => {
      if (exclude === false || (exclude !== false && index2 !== exclude)) {
        o.push(this.getBuilding(building, index2, buildings));
      }
    });
    return o;
  };

  modalBuilding = (modal, buildings, id) => {
    this.setState({
      modalBuilding: modal,
      moreRent: buildings,
      modalBuildingId: id,
    });
  };

  closeModalBuilding = () => {
    this.setState({
      modalBuilding: false,
      moreRent: false,
      modalBuildingId: false,
    });
  };

  render() {
    let show = false;

    const Buildings = this.props.subcategory.map((item, index) => {
      let buildings = [];
      let buildingsIds = [];
      this.props.buildings.buildings.forEach((building) => {
        building.building_categories.forEach((buildingC) => {
          if (
            item.building_category !== null &&
            buildingC.id === item.building_category.id &&
            building.forRentNow === true &&
            !buildingsIds.includes(building.id) &&
            (this.props.countriescontact === false ||
              this.props.countriescontact === building.countries_contact.id)
          ) {
            building.currentCategory = buildingC.category;
            buildings.push(building);
            buildingsIds.push(building.id);
          }
        });
      });
      if (buildings.length > 0) {
        show = true;
        return (
          <div key={uuid()}>
            <div className="BuildingCategoriesSubTitle">{item.title}</div>
            {this.getBuildingsArray(buildings)}
          </div>
        );
      } else {
        return null;
      }
    });

    if (show === false) {
      return null;
    }

    return (
      <>
        <ModalBuildings
          data={this.state.modalBuilding}
          closeModal={this.closeModalBuilding}
          rent={true}
          title={this.props.brokerTitle}
          text={this.props.brokerText}
          broker={
            this.state.modalBuilding.brokers !== null ? (
              <BrokerCard
                broker={this.state.modalBuilding.brokers}
                text={this.props.brokerText}
                title={this.props.brokerTitle}
              />
            ) : undefined
          }
          moreRent={this.state.moreRent}
          modalBuilding={this.modalBuilding}
          getBuildingsArray={this.getBuildingsArray}
          buildingId={this.state.modalBuildingId}
        />
        <div className="BuildingCategoriesContainer">
          <div className="maxWidth">
            <div className="BuildingCategoriesTitle">{this.props.title}</div>
            <div className="BuildingCategoriesWrapper">
              <div className="BuildingCategories ">
                <Marked value={this.props.text} />
              </div>
              <div
                style={{
                  backgroundImage: `url(${
                    process.env.REACT_APP_API + this.props.photo.url
                  })`,
                }}
                className="BuildingCategoriesPhoto"
              />
            </div>
          </div>
        </div>
        {this.props.subcategory !== null ? (
          <div className="BuildingSubcategoriesContainer">
            <div className="maxWidth">{Buildings}</div>
          </div>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    buildings: state.buildings,
    countriescontact: state.countriescontact,
  };
}

export default withRouter(connect(mapStateToProps)(BuildingCategories));
