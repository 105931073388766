import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import "./AutomaticBrokersCard.scss";

class AutomaticBrokersCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    if (this.props.countriescontact === false) {
      return null;
    }
    
    let show = false;

    const Brokers = this.props.brokers.map((item) => {
      let broker = null;
      item.countries_contacts.forEach((item2) => {
        if (broker === null && item2.id === this.props.countriescontact) {
          broker = (
            <div className="AutomaticBrokersCard" key={uuid()}>
              <div className="AutomaticBrokersCardContent">
                <div className="AutomaticBrokersCardBroker">
                  <div
                    className="AutomaticBrokersCardPhoto"
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_API + item.image.url
                      })`,
                    }}
                  ></div>
                  <div className="AutomaticBrokersCardBrokerContact">
                    <div className="bold">{item.name}</div>
                    <div>{item.phone}</div>
                    <div
                      onClick={() => {
                        window.location = "mailto:" + item.email;
                      }}
                    >
                      {item.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      });
      if (broker !== null) {
        show = true;
      }
      return broker;
    });

    if (show === false) {
      return null;
    }

    return (
      <div className="AutomaticBrokersCardContainer">
        <div className="maxWidth AutomaticBrokersCardContainerWrapper">
          <div className="AutomaticBrokersCardTitle">{this.props.label}</div>
        </div>
        <div className="maxWidth AutomaticBrokersCardContainerWrapper">
          {Brokers}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countriescontact: state.countriescontact,
    brokers: state.brokers,
  };
}

export default withRouter(connect(mapStateToProps)(AutomaticBrokersCard));
