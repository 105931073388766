import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

class ImageGalleryC extends Component {
  render() {
    return (
      <ImageGallery
        {...this.props}
        renderLeftNav={(onClick, disabled) => (
          <div
            className="ImageGalleryNav ImageGalleryNavLeft"
            disabled={disabled}
            onClick={onClick}
          >
            <div className="ImageGalleryNavSquare">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          </div>
        )}
        renderRightNav={(onClick, disabled) => (
          <div
            className="ImageGalleryNav ImageGalleryNavRight"
            disabled={disabled}
            onClick={onClick}
          >
            <div className="ImageGalleryNavSquare">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        )}
      />
    );
  }
}

export default ImageGalleryC;
