import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./DevelopmentText.scss";
import Marked from "../../../Utils/Marked";
import Modal from "../../../Utils/Modal";
import ModalBuildings from "../../../Utils/ModalBuildings";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
var GoogleMapsTheme = require("./GoogleMapsTheme.json");
var _ = require("lodash");

class DevelopmentText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      modal: false,
      modalBuilding: false,
    };
  }

  async componentDidMount() {}

  select = (id) => {
    if (this.state.selected === id) {
      this.setState({ selected: false });
    } else {
      this.setState({ selected: id });
    }
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  modalBuilding = (modal) => {
    this.setState({
      modalBuilding: modal,
    });
  };

  closeModalBuilding = () => {
    this.setState({
      modalBuilding: false,
    });
  };

  render() {
    let Buildings = [];
    if (this.state.selected !== false) {
      this.props.buildings.buildings.forEach((item, index) => {
        if (this.state.selected === item.id) {
          Buildings.push(item);
        }
      });
    } else {
      Buildings = this.props.buildings.buildings;
    }

    let n = 0;
    const Development = _.orderBy(
      this.props.buildings.development,
      ["sort"],
      ["desc"]
    );

    return (
      <div className="DevelopmentTextContainer">
        <ModalBuildings
          data={this.state.modalBuilding}
          closeModal={this.closeModalBuilding}
        />
        <Modal isOpen={this.state.modal} onRequestClose={this.toggleModal}>
          <Marked value={this.props.allBuildings} />
        </Modal>
        <div className="DevelopmentTextWrapper maxWidth">
          <div className="redTitle">{this.props.title}</div>
          <div className="DevelopmentTextFilterText">
            {this.props.filterText}
          </div>
          {Development.map((item, index) => {
            if (item.show === false) {
              return null;
            }
            return (
              <div key={index}>
                <div className="DevelopmentText">
                  {item.logo !== null && (
                    <img
                      className="DevelopmentTextLogo"
                      src={process.env.REACT_APP_API + item.logo.url}
                      alt=""
                    />
                  )}

                  <Marked value={item.text} />
                </div>
                <div className="DevelopmentTextBuildings">
                  {Buildings.map((item2, index) => {
                    n = index === 0 ? 1 : n;
                    if (n > 6) {
                      //return null;
                    }
                    if (
                      item2.development === null ||
                      item.id !== item2.development.id
                    ) {
                      return null;
                    }
                    n++;
                    return (
                      <div
                        onClick={() => this.modalBuilding(item2)}
                        key={index}
                        className="DevelopmentTextBuilding"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_API + item2.image.url
                          })`,
                        }}
                      >
                        <div className="DevelopmentTextBuildingSqure">
                          <div className="DevelopmentTextBuildingFade"></div>

                          <div className="DevelopmentTextBuildingTitle">
                            {item2.title}
                          </div>
                          {item2.forRentNow &&
                          this.props.rentText !== undefined &&
                          this.props.rentText !== null &&
                          this.props.rentText.length > 1 ? (
                            <div className="DevelopmentTextBuildingRent">
                              {this.props.rentText}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="redTitle">{this.props.ourBuildings}</div>
          <div className="DevelopmentTextLink" onClick={this.toggleModal}>
            {this.props.linkToBuildingsListText}
          </div>
          <div className="DevelopmentTextGMap">
            <Map
              google={this.props.google}
              zoom={5.7}
              disableDefaultUI={true}
              styles={GoogleMapsTheme}
              initialCenter={{
                lat: 47.02857489596395,
                lng: 20.014198343068635,
              }}
            >
              {Buildings.map((item, index) => {
                if (item.latitude !== null && item.longitude !== null) {
                  return (
                    <Marker
                      key={index}
                      position={{ lat: item.latitude, lng: item.longitude }}
                      onClick={() => this.modalBuilding(item)}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Map>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    buildings: state.buildings,
  };
}

export default (withRouter(connect(mapStateToProps)(GoogleApiWrapper((props) => { 
  return {
  apiKey: props.googleMapsApiKey,
}})(DevelopmentText))));

