import Api from "../../Utils/Api";

const api = (state = Api, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default api;
