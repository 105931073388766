import * as actionType from "./types";
import Api from "../../Utils/Api";

export const setLocale = (locale) => {
  return async (dispatch, getState) => {
    try {
      const response = await Api.get("locales?_locale=" + locale);
      await dispatch({
        type: actionType.SET_COUNTRIESCONTACT,
        id: false,
      });

      return dispatch({
        type: actionType.SET_LOCALE,
        locale: locale,
        locales: response.data,
      });
    } catch (e) {
      return;
    }
  };
};

export const setTag = (tag) => {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: actionType.SET_TAG,
        tag: tag,
      });
      await dispatch(getNews(true));
    } catch (e) {
      return;
    }
  };
};

export const setCountriesContact = (id) => {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: actionType.SET_COUNTRIESCONTACT,
        id: id,
      });
    } catch (e) {
      return;
    }
  };
};

export const setHomepage = (homepage) => ({
  type: actionType.SET_HOMEPAGE,
  homepage: homepage,
});

export const getNews = (clean = false) => ({
  type: actionType.SET_NEWS,
  clean: clean,
});

export function getNav() {
  return async (dispatch, getState) => {
    try {
      const response = await Api.get("menu-items");
      return dispatch({
        type: actionType.SET_NAV,
        payload: response.data,
      });
    } catch (e) {
      return;
    }
  };
}

export function getBrokers() {
  return async (dispatch, getState) => {
    try {
      const response = await Api.get("brokers");
      return dispatch({
        type: actionType.SET_BROKERS,
        payload: response.data,
      });
    } catch (e) {
      return;
    }
  };
}

export function getBuildings() {
  return async (dispatch, getState) => {
    try {
      const locale = getState().locale;
      const responseBuildings = await Api.get("buildings?_locale=" + locale);
      const responseDevelopment = await Api.get(
        "developments?_locale=" + locale
      );

      let map = {};
      responseDevelopment.data.forEach((item, index) => {
        map[item.id] = index;
      });
      return dispatch({
        type: actionType.SET_BUILDINGS,
        payload: {
          buildings: responseBuildings.data,
          development: responseDevelopment.data,
          map: map,
        },
      });
    } catch (e) {
      console.log(e);
      return;
    }
  };
}
