import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Marked from "../../../Utils/Marked";
import "./OurTeam.scss";

class OurTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="OurTeamWrapper maxWidth">
        <div className="redTitle">{this.props.title}</div>
        <div className="OurTeamText">
          <Marked value={this.props.text} />
        </div>
        <div className="OurTeamPersons">
          {this.props.persons.map((item, index) => {
            return (
              <div className="OurTeamPerson" key={index}>
                <div className="OurTeamPersonWrapper">
                  <div
                    className="OurTeamPhoto"
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_API + item.photo.url
                      })`,
                    }}
                  />
                  <div className="OurTeamName">{item.name}</div>
                  <div className="OurTeamJob">{item.job}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(OurTeam));
