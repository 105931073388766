import Router from "./Router";
import "./Styles/main.scss";
import Nav from "./Components/Modules/Nav";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Actions from "./Redux/Actions";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
    this.menuWidth = 304;
    this.mobileSize = 768;
  }

  async componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
    await this.props.dispatch(Actions.getNav());
    await this.props.dispatch(Actions.getBrokers());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = (evt) => {
    let width = window.innerWidth;
    if (width < 768 && this.state.isMobile !== true) {
      this.setState({
        isMobile: true,
      });
    }
    if (width >= 768 && this.state.isMobile !== false) {
      this.setState({
        isMobile: false,
      });
    }
  };

  render() {
    if (this.props.nav === false || this.props.brokers === false) {
      return null;
    }
    return (
      <div className="App">
        <Nav
          menuWidth={this.menuWidth}
          mobileSize={this.mobileSize}
          isMobile={this.state.isMobile}
        />
        <div
          className="content"
          style={{ marginLeft: this.state.isMobile ? 0 : this.menuWidth }}
        >
          {this.props.locale !== false ? <Router /> : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    nav: state.nav,
    brokers: state.brokers,
    locale: state.locale,
    locales: state.locales,
  };
}

export default withRouter(connect(mapStateToProps)(App));
