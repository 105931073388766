import * as actionType from "../Actions/types";

const locales = (state = false, action) => {
  switch (action.type) {
    case actionType.SET_LOCALE:
      if (Array.isArray(action.locales)) {
        let map = {};

        action.locales.forEach((item, index) => {
          map[item.slug] = item.text;
        });

        return map;
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default locales;
