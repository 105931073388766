import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Marked from "../../../Utils/Marked";
import "./OurValues.scss";

class OurValues extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="OurValuesContainer">
        <div className="OurValuesWrapper maxWidth">
          <div className="redTitle">{this.props.title}</div>
          <div className="OurValuesContent">
            <div className="OurValuesCenterLine"></div>
            {this.props.values.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    index % 2 === 0
                      ? "OurValuesContentTextEven"
                      : "OurValuesContentTextOdd"
                  }
                >
                  <div className="OurValuesCircle" />
                  <Marked value={item.text} />
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="OurValuesBg"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_API + this.props.image.url
            })`,
          }}
        ></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(OurValues));
