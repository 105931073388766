import * as actionType from "../Actions/types";

const nav = (state = false, action) => {
  switch (action.type) {
    case actionType.SET_NAV:
      if (Array.isArray(action.payload)) {
        let locales = {};
        let map = {};

        action.payload.forEach((item, index) => {
          if (item.page !== null) {
            map[item.id] = item.page.slug;
          }
        });

        action.payload.forEach((item, index) => {
          if (item.page !== null) {
            let localizations = {
              locale: item.locale,
            };

            item.localizations.forEach((l) => {
              localizations[l.locale] = map[l.id];
            });

            item.localizationsMap = localizations;

            locales[item.page.slug] = localizations;
          }
        });

        return {
          items: action.payload,
          locales: locales,
          map: map,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default nav;
