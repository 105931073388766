import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Marked from "../../../Utils/Marked";
import "./TwoBrokerCard.scss";

class TwoBrokerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}
  render() {
    if (this.props.broker1 === null || this.props.broker1.image === null) {
      return null;
    }
    if (this.props.broker2 === null || this.props.broker2.image === null) {
      return null;
    }
    return (
      <div className="TwoBrokerCardContainer">
        <div className="maxWidth">
          <div className="TwoBrokerCardTitle">{this.props.title}</div>

          <div className="maxWidth TwoBrokerCardWrapper">
            <div className="TwoBrokerCard">
              <div className="TwoBrokerCardContent">
                <Marked value={this.props.textBroker1} />
                <div className="TwoBrokerCardBroker">
                  <div
                    className="TwoBrokerCardPhoto"
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_API + this.props.broker1.image.url
                      })`,
                    }}
                  ></div>
                  <div className="TwoBrokerCardBrokerContact">
                    <div className="bold">{this.props.broker1.name}</div>
                    <div>{this.props.broker1.phone}</div>
                    <div>{this.props.broker1.email}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="TwoBrokerCard TwoBrokerCardSecond">
              <div className="TwoBrokerCardContent">
                <Marked value={this.props.textBroker2} />
                <div className="TwoBrokerCardBroker">
                  <div
                    className="TwoBrokerCardPhoto"
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_API + this.props.broker2.image.url
                      })`,
                    }}
                  ></div>
                  <div className="TwoBrokerCardBrokerContact">
                    <div className="bold">{this.props.broker2.name}</div>
                    <div>{this.props.broker2.phone}</div>
                    <div>{this.props.broker2.email}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countriescontact: state.countriescontact,
  };
}

export default withRouter(connect(mapStateToProps)(TwoBrokerCard));
