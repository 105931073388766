import * as actionType from "../Actions/types";

const buildings = (state = { buildings: [], development: [] }, action) => {
  switch (action.type) {
    case actionType.SET_BUILDINGS:
      return {
        buildings: action.payload.buildings,
        development: action.payload.development,
        map: action.payload.map,
      };
    default:
      return state;
  }
};

export default buildings;
