import { combineReducers } from "redux";
import api from "./api";
import locale from "./locale";
import homepage from "./homepage";
import news from "./news";
import nav from "./nav";
import locales from "./locales";
import buildings from "./buildings";
import tag from "./tag";
import brokers from "./brokers";
import countriescontact from "./countriescontact";
export default combineReducers({
  api,
  locale,
  homepage,
  news,
  nav,
  buildings,
  locales,
  tag,
  brokers,
  countriescontact,
});
