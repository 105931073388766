import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./FullImageWithText.scss";
import Marked from "../../../Utils/Marked";

class FullImageWithText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgA: false,
      imgB: false,
      img: "imgA",
      text: "",
      isOnTop: true,
    };
    this.duration = 60;
    this.timeout = null;
    this.set = null;
  }

  async componentDidMount() {
    window.addEventListener("scroll", this.resize);
    this.resize();
    this.set = "pending";
    this.setState(
      {
        imgA: process.env.REACT_APP_API + this.props.image.url,
        text: this.props.text,
      },
      () => {
        this.set = "imgB";
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps) {
    if (this.props.image.url !== prevProps.image.url) {
      const img = new Image();
      img.src = process.env.REACT_APP_API + this.props.image.url;
      img.onload = () => {
        const set = this.set;
        if (set === "pending") {
          return;
        }
        this.set = "pending";
        this.setState(
          {
            [set]: process.env.REACT_APP_API + this.props.image.url,
            img: set,
            text: this.props.text,
          },
          () => {
            this.set = set === "imgA" ? "imgB" : "imgA";
          }
        );
      };
    } else if (this.props.text !== prevProps.text) {
      this.setState({
        text: this.props.text,
      });
    }
  }

  resize = (evt) => {
    const isOnTop = window.scrollY <= 0;
    if (this.state.isOnTop !== isOnTop) {
      this.setState({
        isOnTop: isOnTop,
      });
    }
  };

  render() {
    return (
      <div className="FullImageWithTextWrapper">
        {this.state.imgA !== false ? (
          <div
            className="FullImageWithTextPhoto"
            style={{
              backgroundImage: `url(${this.state.imgA})`,
            }}
          ></div>
        ) : null}
        {this.state.imgB !== false ? (
          <div
            className="FullImageWithTextPhoto"
            style={{
              backgroundImage: `url(${this.state.imgB})`,
              opacity: this.state.img === "imgB" ? 1 : 0,
              transition: this.state.isOnTop
                ? "opacity 0.5s ease-in-out"
                : "none",
            }}
          ></div>
        ) : null}
        <div className="maxWidth">
          <div className="FullImageWithTextText">
            <Marked value={this.state.text} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(FullImageWithText));
