import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./ContactsCountries.scss";

class ContactsCountries extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    let Contacts = [];

    for (let i = 0; i < this.props.countries_contacts.length; i++) {
      let item = this.props.countries_contacts[i];
      Contacts.push(
        <div
          key={i}
          className={
            this.props.theme === "light"
              ? "ContactsCountriesItemLight"
              : "ContactsCountriesItem"
          }
        >
          <div className="ContactsCountriesItemContact">
            <div className="ContactsCountriesItemContactCountry">
              <div>{item.country}</div>
              {item.isHeadOffice ? (
                <div className="ContactsCountriesItemContactHo">
                  {this.props.headOfficeText}
                </div>
              ) : null}
            </div>
            <div>{item.phone}</div>
            <div
              className="ContactsCountriesItemContactEmail"
              onClick={() => {
                window.location = "mailto:" + item.email;
              }}
            >
              {item.email}
            </div>
            <div>{item.address}</div>
          </div>
          {this.props.theme === "light" ? null : (
            <div
              className="ContactsCountriesItemImage"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_API +
                  (item.image.formats.large !== undefined
                    ? item.image.formats.large.url
                    : item.image.url)
                })`,
              }}
            ></div>
          )}
        </div>
      );
    }
    return (
      <div
        className={
          this.props.theme === "light"
            ? "ContactsCountriesLight"
            : this.props.theme === "bigLight"
            ? "ContactsCountriesBigLight"
            : "ContactsCountries"
        }
      >
        {this.props.theme === "bigLight" ? null : (
          <div className="ContactsCountriesHeader maxWidth">
            <div className="ContactsCountriesTitle">{this.props.title}</div>
          </div>
        )}

        <div
          className={
            this.props.theme === "light"
              ? "ContactsCountriesItemsContainerLight maxWidth"
              : "ContactsCountriesItemsContainer maxWidth"
          }
        >
          {Contacts}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    news: state.news,
    locale: state.locale,
    nav: state.nav,
  };
}

export default withRouter(connect(mapStateToProps)(ContactsCountries));
