import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Page from "./Components/Pages/Page";
import News from "./Components/News";

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Page} />
        <Route exact path="/news/:id" component={News} />
        <Route exact path="/aktuality/:id" component={News} />
        <Route exact path="/:slug" component={Page} />
        <Route exact path="*" component={Page} />
      </Switch>
    );
  }
}

export default Router;
