import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./News.scss";
import ImageGallery from "../../Utils/ImageGallery";
import Marked from "../../Utils/Marked";
var dateFormat = require("dateformat");

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: false,
    };
  }

  async componentDidMount() {
    this.get(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {}

  get = async (id) => {
    try {
      const res = await this.props.api.get("news-items/" + id);
      this.setState({
        news: res.data,
      });
    } catch (err) {
      this.props.history.push("/");
    }
  };

  render() {
    if (this.state.news === false) {
      return (
        <div className="fullAndCenter">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      );
    }

    let images = [];
    if (
      this.state.news.gallery !== null &&
      this.state.news.gallery.length > 0
    ) {
      this.state.news.gallery.forEach((item) => {
        images.push({
          original: process.env.REACT_APP_API + item.url,
          thumbnail: process.env.REACT_APP_API + item.formats.thumbnail.url,
        });
      });
    }

    return (
      <div className="NewsPage maxWidth">
        <img
          src={process.env.REACT_APP_API + this.state.news.image.url}
          alt=""
          className="NewsPageMainImage"
        />
        <div className="NewsPageTextContainer">
          <div className="NewsPageTextTitle">{this.state.news.title}</div>
          <div className="NewsPageTextDate">
            {dateFormat(this.state.news.date, "dd.m. yyyy")}
          </div>
          <div className="NewsPageTextText">
            <Marked value={this.state.news.text} />
          </div>
        </div>
        {this.state.news.gallery !== null &&
        this.state.news.gallery.length > 0 ? (
          <div className="NewsPageGallery">
            <ImageGallery
              items={images}
              renderLeftNav={(onClick, disabled) => {
                <button
                  className="image-gallery-custom-left-nav"
                  disabled={disabled}
                  onClick={onClick}
                />;
              }}
              showThumbnails={true}
              showFullscreenButton={false}
              showPlayButton={false}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { api: state.api };
}

export default withRouter(connect(mapStateToProps)(News));
