import * as actionType from "../Actions/types";

const locale = (state = false, action) => {
  switch (action.type) {
    case actionType.SET_LOCALE:
      return action.locale;
    default:
      return state;
  }
};

export default locale;
