import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./LabelWithBox.scss";
import Marked from "../../../Utils/Marked";

class LabelWithBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      modal: false,
      modalBuilding: false,
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="LabelWithBox maxWidth">
        <div className="LabelWithBoxTitle blackTitle">{this.props.title}</div>
        <div className="LabelWithBoxText">
          <Marked value={this.props.text} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(LabelWithBox));
