import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./InvestorSlider.scss";
import Marked from "../../../Utils/Marked";
import ImageGallery from "react-image-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
var _ = require("lodash");

class InvestorSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: false,
      index: false,
      indexStart: false,
    };
    this.refImg = React.createRef();
  }

  async componentDidMount() {
    this.setImages();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.images !== this.props.images) {
      this.setImages();
    }
  }

  setImages = () => {
    let images = [];
    this.props.images.forEach((item) => {
      images.push({
        original: process.env.REACT_APP_API + item.url,
        thumbnail:
          process.env.REACT_APP_API +
          (item.formats !== null && item.formats.thumbnail !== undefined
            ? item.formats.thumbnail.url
            : item.url),
        year: item.caption,
      });
    });
    images = _.orderBy(images, ["year"]);

    const Gallery = (
      <ImageGallery
        ref={this.refImg}
        items={images}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        onSlide={this.onSlide}
        showNav={false}
        startIndex={this.state.indexStart}
      />
    );

    this.setState({
      images: images,
      index: images.length - 1,
      indexStart: images.length - 1,
      gallery: Gallery,
    });
  };

  clickMinus = () => {
    let index = this.state.index - 1;
    if (index === -1) {
      index = this.state.images.length - 1;
    }
    this.refImg.current.slideToIndex(index);
    //this.setState({ index: index });
  };

  clickPlus = () => {
    let index = this.state.index + 1;
    if (index === this.state.images.length) {
      index = 0;
    }
    this.refImg.current.slideToIndex(index);
    //this.setState({ index: index });
  };

  onSlide = (i) => {
    this.setState({ index: i });
  };

  render() {
    if (this.state.images === false) {
      return null;
    }

    return (
      <div className="InvestorSlider maxWidth">
        <div className="InvestorSliderTextBox">
          <div className="InvestorSliderTitle ">{this.props.title}</div>
          <div className="InvestorSliderText">
            <Marked value={this.props.text} />
          </div>
        </div>
        <div className="InvestorSliderImageBoxWrapper">
          <div className="InvestorSliderImageBox">
            <ImageGallery
              ref={this.refImg}
              items={this.state.images}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              onSlide={this.onSlide}
              showNav={false}
              startIndex={this.state.indexStart}
            />
          </div>
          <div className="InvestorSliderNav">
            <div onClick={this.clickMinus} className="InvestorSliderNavPlus">
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div className="InvestorSliderNavYear">
              {this.state.images[this.state.index].year}
            </div>
            <div onClick={this.clickPlus} className="InvestorSliderNavMinus">
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locale: state.locale,
  };
}

export default withRouter(connect(mapStateToProps)(InvestorSlider));
