export const SET_LOCALE = "SET_LOCALE";
export const SET_HOMEPAGE = "SET_HOMEPAGE";
export const SET_NEWS = "SET_NEWS";
export const SET_NAV = "SET_NAV";
export const SET_NEWS_OK = "SET_NEWS_OK";
export const SET_BUILDINGS = "SET_BUILDINGS";
export const SET_LOCALES = "SET_LOCALES";
export const SET_TAG = "SET_TAG";
export const SET_BROKERS = "SET_BROKERS";
export const SET_COUNTRIESCONTACT = "SET_COUNTRIESCONTACT";
