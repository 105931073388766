import { call, put, select, takeLeading } from "redux-saga/effects";
import Api from "../../Utils/Api";
import * as actionType from "../Actions/types";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchNews(action) {
  const state = yield select();
  try {
    let get = async (state) => {
      const news = state.news;
      const locale = state.locale;
      const tag = state.tag;

      let tagString = "";
      tag.forEach((item) => {
        tagString += "&tags_in=" + item;
      });

      if (locale === false) {
        throw new Error();
      }
      const start = action.clean ? 0 : news.start;

      const response = await Api.get(
        "news-items?&_sort=date:desc&_limit=" +
          news.limit +
          "&_start=" +
          start +
          (locale ? "&_locale=" + locale : "") +
          tagString
      );
      return response.data;
    };
    const news = yield call(get, state);
    yield put({ type: actionType.SET_NEWS_OK, news, clean: action.clean });
  } catch (e) {}
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* mySaga() {
  yield takeLeading(actionType.SET_NEWS, fetchNews);
}

export default mySaga;
