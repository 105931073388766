import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import NavLogo from "../../../Images/NavLogo.svg";
import NavMobileLogo from "../../../Images/NavMobileLogo.svg";
import Czech from "../../../Images/Czech.png";
import English from "../../../Images/English.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import * as Actions from "../../../Redux/Actions";
var _ = require("lodash");
var parser = require("tld-extract");

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      locales: {},
      menuVisible: true,
      isMobile: false,
    };
    this.locales = [
      { slug: "cs-CZ", name: "Česky", image: Czech },
      { slug: "en", name: "English", image: English },
    ];
  }

  async componentDidMount() {
    try {
      this.findLocale();
      this.setHomepage();
      this.props.dispatch(Actions.getNews());
      this.props.dispatch(Actions.getBuildings());
      this.isMobile();
    } catch (err) {}
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.isMobile !== this.props.isMobile) {
      this.isMobile();
    }

    if (prevProps.locale !== this.props.locale) {
      this.setHomepage();
      this.props.dispatch(Actions.getNews(true));
      this.props.dispatch(Actions.getBuildings());
    }
  }

  isMobile = () => {
    if (this.props.isMobile) {
      this.setState({
        menuVisible: false,
      });
    } else {
      this.setState({
        menuVisible: true,
      });
    }
  };

  componentWillUnmount() {}

  findLocale = () => {
    let slug = this.props.location.pathname.substr(1);
    if (typeof this.props.nav.locales[slug] !== "undefined") {
      this.props.dispatch(
        Actions.setLocale(this.props.nav.locales[slug].locale)
      );
    } else {
      try {
        let tld = parser(document.location.href);
        if (tld.tld !== undefined && tld.tld === "cz") {
          this.props.dispatch(Actions.setLocale("cs-CZ"));
        } else {
          this.props.dispatch(Actions.setLocale(process.env.REACT_APP_LOCALE));
        }
      } catch (e) {
        this.props.dispatch(Actions.setLocale(process.env.REACT_APP_LOCALE));
      }
    }
  };

  setHomepage = () => {
    if (Array.isArray(this.props.nav.items)) {
      let homepage = false;
      for (
        let i = 0;
        i < this.props.nav.items.length && homepage === false;
        i++
      ) {
        if (
          this.props.nav.items[i].page !== null &&
          this.props.nav.items[i].page.isHomepage === true &&
          this.props.nav.items[i].locale === this.props.locale
        ) {
          this.props.dispatch(
            Actions.setHomepage(this.props.nav.items[i].page.slug)
          );
          homepage = true;
        }
      }
    }
  };

  selectLang = async (locale) => {
    await this.props.dispatch(Actions.setLocale(locale));
    let slug = this.props.location.pathname.substr(1);

    if (
      typeof this.props.nav.locales[slug] !== "undefined" &&
      typeof this.props.nav.locales[slug][locale] !== "undefined"
    ) {
      this.props.history.push(this.props.nav.locales[slug][locale]);
    } else {
      this.props.history.push("/");
    }
  };

  toggleMenu = () => {
    if (this.props.isMobile) {
      this.setState({
        menuVisible: !this.state.menuVisible,
      });
    }
  };

  render() {
    if (this.props.nav.items.length === 0) {
      return null;
    }

    let BoldItems = [];
    let RegularItems = [];

    this.props.nav.items.forEach((item) => {
      if (
        item.bold === true &&
        this.props.locale === item.locale &&
        item.showInNav === true &&
        item.page !== null
      ) {
        BoldItems.push(item);
      }
      if (
        item.bold === false &&
        this.props.locale === item.locale &&
        item.showInNav === true &&
        item.page !== null
      ) {
        RegularItems.push(item);
      }
    });

    BoldItems = _.orderBy(BoldItems, ["position"], ["name"]);
    RegularItems = _.orderBy(RegularItems, ["position"], ["name"]);

    return (
      <>
        {this.state.menuVisible === false ? (
          <div className="mobileMenuButton">
            <Link className="navLogoContainer" to="/">
              <img src={NavMobileLogo} className="navMobileMenuLogo" alt="" />
            </Link>
            <div
              className="mobileMenuButtonClickContainer"
              onClick={this.toggleMenu}
            >
              <div className="mobileMenuButtonContainer">
                <div>Menu</div>
                <div className="mobileMenuButtonIconWrapper">
                  {" "}
                  <FontAwesomeIcon
                    icon={faBars}
                    className="mobileMenuButtonIcon"
                    style={
                      this.props.isMobile && !this.state.menuVisible
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className="nav"
          // style={[
          //   this.state.menuVisible ? { display: "flex" } : { display: "none" },
          //   this.props.isMobile ? { width: "100%" } : { display: 304 },
          // ]}
          style={{
            display: this.state.menuVisible ? "flex" : "none",
            width: this.props.isMobile ? "100%" : this.props.menuWidth,
          }}
        >
          {this.props.isMobile ? (
            <div className="navClose" onClick={this.toggleMenu}>
              <FontAwesomeIcon icon={faTimes} className="navCloseIcon" />
            </div>
          ) : null}

          <Link className="navLogoContainer" to="/" onClick={this.toggleMenu}>
            <img src={NavLogo} className="navLogo" alt="" />
          </Link>
          <div className="navItemsContainer">
            <div className="navBoldItems">
              {BoldItems.map((item, index) => (
                <Link
                  to={"/" + item.page.slug}
                  className="navBoldItem"
                  key={index}
                  onClick={this.toggleMenu}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="navItems">
              {RegularItems.map((item, index) => (
                <Link
                  to={"/" + item.page.slug}
                  className="navItem"
                  key={index}
                  onClick={this.toggleMenu}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="navLangs">
              {this.locales.map((item, index) => {
                if (item.slug === this.props.locale) {
                  return null;
                }
                return (
                  <p
                    onClick={() => this.selectLang(item.slug)}
                    className="navLangItem"
                    key={index}
                  >
                    {item.name}
                    <img
                      src={item.image}
                      className="navLangImg"
                      alt={item.name}
                    />
                  </p>
                );
              })}
              <div className="navSocial">
                <a
                  className="navSocialElement navSocialIconF"
                  href="https://www.facebook.com/RCEuropeCZ/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  className="navSocialElement navSocialIconIg"
                  href="https://www.instagram.com/rc_europe_cz/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.api,
    locale: state.locale,
    nav: state.nav,
  };
}

export default withRouter(connect(mapStateToProps)(Nav));
