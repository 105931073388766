import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Marked from "../../../Utils/Marked";
import "./About.scss";
import ModalBuildings from "../../../Utils/ModalBuildings";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  async componentDidMount() {}

  modal = (modal) => {
    this.setState({
      modal: modal,
    });
  };

  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  render() {
    return (
      <div
        className={
          this.props.color === "darkBg"
            ? "AboutContainer AboutDark"
            : "AboutContainer"
        }
      >
        <ModalBuildings data={this.state.modal} closeModal={this.closeModal} />
        {/* <div
          className="AboutMainPhoto"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_API + this.props.image.url
            })`,
          }}
        > */}
        <div className="AboutTitle maxWidth">{this.props.title}</div>
        <div className="AboutPhotoContainer maxWidth">
          <div
            className={
              this.props.color === "darkBg"
                ? "AboutPhoto AboutPhotoDark"
                : "AboutPhoto"
            }
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_API + this.props.image.url
              })`,
            }}
          >
            {this.props.building !== null ? (
              <div
                className="AboutPhotoLinkBuilding"
                onClick={() => this.modal(this.props.building)}
              >
                <Marked
                  markedOptions={{ baseUrl: process.env.REACT_APP_API }}
                  value={this.props.imageName}
                />
                <div className="AboutPhotoLinkBuildingButton">
                  {this.props.linkText}
                </div>
              </div>
            ) : null}
            <div>
              {this.props.box.map((item, index) => {
                return (
                  <div
                    className={
                      this.props.color === "darkBg"
                        ? "AboutBoxLight"
                        : "AboutBoxDark"
                    }
                    key={index}
                  >
                    <Marked
                      markedOptions={{ baseUrl: process.env.REACT_APP_API }}
                      value={item.text}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(About));
