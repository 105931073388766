import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Actions from "../../../Redux/Actions";
import "./SelectCountry.scss";

class SelectCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  setTag = (id) => {
    this.props.dispatch(Actions.setCountriesContact(id));
  };

  render() {
    return (
      <div className="SelectCountryContainer maxWidth">
        <h2 className="redTitle">{this.props.label}</h2>
        <div className="uppercase SelectCountryItems">
          <div
            className={
              this.props.countriescontact === false
                ? "uppercase SelectCountryItem SelectCountryItemSelected"
                : "uppercase SelectCountryItem "
            }
            onClick={() => {
              this.setTag(false);
            }}
          >
            {this.props.allText}
          </div>
          {this.props.countries_contacts.map((item, index) => {
            return (
              <div
                className={
                  this.props.countriescontact === item.id
                    ? "uppercase SelectCountryItem SelectCountryItemSelected"
                    : "uppercase SelectCountryItem "
                }
                onClick={() => {
                  this.setTag(item.id);
                }}
                key={item.id}
              >
                {item.country}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countriescontact: state.countriescontact,
  };
}

export default withRouter(connect(mapStateToProps)(SelectCountry));
