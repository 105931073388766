import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Marked from "../../../Utils/Marked";
import "./BrokerCard.scss";

class BrokerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    if (
      this.props.broker === undefined ||
      this.props.broker === null ||
      this.props.broker.image === null
    ) {
      return null;
    }
    return (
      <div className="BrokerCardContainer">
        <div className="maxWidth">
          <div className="BrokerCardTitle">{this.props.title}</div>

          <div className="BrokerCard maxWidth">
            <Marked value={this.props.text} />
            <div className="BrokerCardBroker">
              <div
                className="BrokerCardPhoto"
                style={{
                  backgroundImage: `url(${
                    process.env.REACT_APP_API + this.props.broker.image.url
                  })`,
                }}
              ></div>
              <div className="BrokerCardBrokerContact">
                <div className="bold">{this.props.broker.name}</div>
                <div>{this.props.broker.phone}</div>
                <div>{this.props.broker.email}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(BrokerCard));
