import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./SmallImageTitle.scss";

class SmallImageTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <div className="SmallImageTitleWrapper">
        <div
          className="SmallImageTitlePhoto"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_API + this.props.image.url
            })`,
          }}
        >
          <div className="SmallImageTitleFade"></div>
          <div className="SmallImageTitleTitle">{this.props.title}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(SmallImageTitle));
