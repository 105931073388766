import axios from "axios";

const instance = axios.create({
  // .. where we make our configurations
  //baseURL: process.env.REACT_APP_API,

  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://rceurope.com/api"
      : "/api",
});

export default instance;
