import * as actionType from "../Actions/types";

const countriescontact = (state = false, action) => {
  switch (action.type) {
    case actionType.SET_COUNTRIESCONTACT:
      return action.id;
    default:
      return state;
  }
};

export default countriescontact;
