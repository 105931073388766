import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./Home.scss";

class Home extends Component {
  constructor(props) {
    super(props);
    let s = this.props.image.map((item, index) => {
      return 1;
    });
    let o = this.props.image.map((item, index) => {
      return index === 0 ? 1 : 0;
    });
    this.state = {
      scale: s,
      opacity: o,
      loaded: false,
    };
    this.duration = 30;
    this.timeout = null;
  }

  async componentDidMount() {
    this.timeout = setTimeout(() => {
      this.scale(0, 1.2);
    }, 100);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeout2);
    clearTimeout(this.timeout3);
  }

  scale = (i, to) => {
    if (i === this.props.image.length) {
      i = 0;
    }

    let a = this.state.scale;
    a[i] = to;
    this.setState({
      scale: a,
    });
    if (to !== 1) {
      this.timeout3 = setTimeout(() => {
        this.scale(i, 1);
      }, this.duration * 900);
      this.timeout = setTimeout(() => {
        this.scale(i + 1, 1.3);
      }, (this.duration / 2) * 1000);
      this.timeout2 = setTimeout(() => {
        this.opacity(i + 1, i);
      }, (this.duration / 2) * 1000);
    }
  };

  opacity = (i, y) => {
    if (i === this.props.image.length) {
      i = 0;
    }
    let a = this.state.opacity;
    a[i] = 1;
    a[y] = 0;
    this.setState({
      opacity: a,
    });
  };

  loaded = () => {
    this.setState({
      loaded: true,
    });
  };
  render() {
    return (
      <div className="HomeMainPhotoWrapper">
        {this.props.video !== null ? (
          <div className="HomeMainPhotoAnimation">
            <video playsInline autoPlay muted loop className="CoverVideo">
              <source
                src={process.env.REACT_APP_API + this.props.video.url}
                type="video/mp4"
              />
            </video>
          </div>
        ) : (
          <>
            {this.props.image.length === 1 ? (
              <div className="HomeMainPhotoAnimation">
                <div
                  className="HomeMainPhoto"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_API + this.props.image[0].url
                    })`,
                  }}
                ></div>
              </div>
            ) : (
              <>
                {this.props.image.map((item, index) => {
                  let o = this.state.scale[index] === 1 ? 0 : this.duration;
                  return (
                    <div
                      key={index}
                      className="HomeMainPhotoAnimation"
                      style={{
                        transform: "scale(" + this.state.scale[index] + ")",
                        opacity: this.state.opacity[index],
                        transition:
                          "transform " +
                          o +
                          "s ease-in-out, opacity " +
                          5 +
                          "s ease-in-out",
                      }}
                    >
                      <div
                        className="HomeMainPhoto"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_API + item.url
                          })`,
                        }}
                      ></div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
        <div className="HomeBox">
          <div className="HomeTitleText">{this.props.title}</div>
          <div className="HomeText">{this.props.text}</div>
          <Link to={this.props.link} className="HomeButton">
            {this.props.linkTitle}
            <FontAwesomeIcon icon={faChevronRight} className="HomeButtonIcon" />
          </Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(Home));
