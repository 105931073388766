import * as actionType from "../Actions/types";
var _ = require("lodash");

const news = (
  state = { data: [], start: 0, limit: 10, fetching: false },
  action
) => {
  switch (action.type) {
    case actionType.SET_NEWS_OK:
      let newState = _.cloneDeep(state);
      if (action.clean) {
        newState.start = state.limit + 0;
        newState.data = action.news;
      } else {
        newState.start = state.limit + state.start;
        action.news.forEach((item) => {
          if (!_.find(state.data, { id: item.id })) {
            newState.data.push(item);
          }
        });
      }
      return newState;
    default:
      return state;
  }
};
export default news;
