import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Home from "../../Modules/Content/Home";
import About from "../../Modules/Content/About";
import RcAboutRedBox from "../../Modules/Content/RcAboutRedBox";
import News from "../../Modules/Content/News";
import ContactsCountries from "../../Modules/Content/ContactsCountries";
import FullImageWithText from "../../Modules/Content/FullImageWithText";
import LeftTextRightImages from "../../Modules/Content/LeftTextRightImages";
import OurValues from "../../Modules/Content/OurValues";
import OurTeam from "../../Modules/Content/OurTeam";
import Portfolio from "../../Modules/Content/Portfolio";
import DevelopmentText from "../../Modules/Content/DevelopmentText";
import LabelWithBox from "../../Modules/Content/LabelWithBox";
import InvestorSlider from "../../Modules/Content/InvestorSlider";
import TextAndImage from "../../Modules/Content/TextAndImage";
import BrokerCard from "../../Modules/Content/BrokerCard";
import TwoBrokerCard from "../../Modules/Content/TwoBrokerCard";
import AutomaticBrokersCard from "../../Modules/Content/AutomaticBrokersCard";
import BuildingCategories from "../../Modules/Content/BuildingCategories";
import NewsBig from "../../Modules/Content/NewsBig";
import SmallImageTitle from "../../Modules/Content/SmallImageTitle";
import SelectCountry from "../../Modules/Content/SelectCountry";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: false,
      currentPage: false,
      prevPage: false,
    };
  }

  componentDidMount() {
    this.getPage();
  }

  componentDidUpdate(prevProps) {
    if (
      (typeof this.props.match.params.slug !== "undefined" &&
        this.props.match.params.slug !== prevProps.match.params.slug) ||
      (this.props.homepage !== prevProps.homepage &&
        typeof this.props.match.params.slug === "undefined") ||
      (typeof this.props.match.params.slug === "undefined" &&
        typeof prevProps.match.params.slug !== "undefined")
    ) {
      let scroll = this.props.locale === prevProps.locale;
      this.getPage(scroll);
    }
  }

  getPage = async (scroll = true) => {
    let slug =
      typeof this.props.match.params.slug === "undefined"
        ? this.props.homepage
        : this.props.match.params.slug;

    if (slug === false) {
      return;
    }
    try {
      const res = await this.props.api.get(
        "page-contents?slug=" + slug + "&_locale=" + this.props.locale
      );

      if (!Array.isArray(res.data) || res.data.length === 0) {
        throw new Error("Not Found");
      }
      this.setState(
        {
          page: res.data[0],
          currentPage: this.makePake(res.data[0]),
        },
        () => {
          if (scroll) {
            window.scrollTo(0, 0);
          }
        }
      );
    } catch (err) {
      this.setState({
        page: null,
      });
    }
  };

  getModule = (module, params) => {
    switch (module) {
      case "page-module.home":
        return <Home {...params} />;
      case "page-module.about":
        return <About {...params} />;
      case "page-module.rc-about-red-box":
        return <RcAboutRedBox {...params} />;
      case "page-module.news-box":
        return <News {...params} />;
      case "page-module.countries-contact-box":
        return <ContactsCountries {...params} />;
      case "page-module.full-image-with-text":
        return <FullImageWithText {...params} />;
      case "page-module.left-text-right-images":
        return <LeftTextRightImages {...params} />;
      case "page-module.our-values":
        return <OurValues {...params} />;
      case "page-module.our-team":
        return <OurTeam {...params} />;
      case "page-module.portfolio":
        return <Portfolio {...params} />;
      case "page-module.development-text":
        return <DevelopmentText {...params} />;
      case "page-module.label-with-box":
        return <LabelWithBox {...params} />;
      case "page-module.investor-slider":
        return <InvestorSlider {...params} />;
      case "page-module.text-and-image":
        return <TextAndImage {...params} />;
      case "page-module.broker-card":
        return <BrokerCard {...params} />;
      case "page-module.two-borekers-card":
        return <TwoBrokerCard {...params} />;
      case "page-module.building-categories":
        return <BuildingCategories {...params} />;
      case "page-module.news-big":
        return <NewsBig {...params} />;
      case "page-module.small-image-title":
        return <SmallImageTitle {...params} />;
      case "page-module.select-country":
        return <SelectCountry {...params} />;
      case "page-module.automatic-brokers-card":
        return <AutomaticBrokersCard {...params} />;
      default:
        return null;
    }
  };

  makePake = (page) => {
    const Modules = page.content.map((item, index) => {
      item.key = index;
      return this.getModule(item.__component, item);
    });
    return Modules;
  };

  render() {
    if (this.state.page === null) {
      return <div className="fullAndCenter">Error 404</div>;
    }
    if (this.state.page === false) {
      return (
        <div className="fullAndCenter">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      );
    }

    // const Modules = this.state.page.content.map((item, index) => {
    //   item.key = index;
    //   return this.getModule(item.__component, item);
    // });

    return <>{this.state.currentPage}</>;
  }
}

function mapStateToProps(state) {
  return {
    api: state.api,
    locale: state.locale,
    homepage: state.homepage,
  };
}

export default withRouter(connect(mapStateToProps)(Page));
