import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Marked from "../../../Utils/Marked";
import "./RcAboutRedBox.scss";
import LogoWhite from "../../../Images/LogoWhite.svg";

class RcAboutRedBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="RcAboutRedBoxContainer">
        <div className="RcAboutRedBox maxWidth">
          <img src={LogoWhite} alt="" className="RcAboutRedBoxImage" />
          <Marked value={this.props.text} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(RcAboutRedBox));
