import React, { Component } from "react";
import ImageGallery from "./ImageGallery";
import Marked from "./Marked";
import Modal from "./Modal";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeEurope, faShareAlt } from "@fortawesome/free-solid-svg-icons";
var _ = require("lodash");

class ModalBuildings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
    };
    this.refModal = React.createRef();
  }

  componentDidMount() {
    this.disableBodyScroll();
    this.setState({ data: _.cloneDeep(this.props.data) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props && this.refModal.current !== null) {
      this.refModal.current.scrollTop = 0;
    }
    if (prevProps.data !== this.props.data && this.props.data !== false) {
      this.setState({ data: _.cloneDeep(this.props.data) });
    }
    this.disableBodyScroll();
  }

  disableBodyScroll = () => {
    if (this.props.data !== false) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  render() {
    let images = [];
    if (this.props.data !== false) {
      if (this.props.data.video !== null) {
        images.push({
          renderItem: () => (
            <div className="image-gallery-image">
              <video playsInline autoPlay muted loop className="CoverVideo">
                <source
                  src={process.env.REACT_APP_API + this.props.data.video.url}
                  type="video/mp4"
                />
              </video>
            </div>
          ),
        });
      }
      images.push({
        original: process.env.REACT_APP_API + this.props.data.image.url,
        thumbnail:
          process.env.REACT_APP_API +
          this.props.data.image.formats.thumbnail.url,
      });
      if (this.props.data.gallery !== null) {
        this.props.data.gallery.forEach((item) => {
          if(item.formats !== undefined) {
            images.push({
              original: process.env.REACT_APP_API + item.url,
              thumbnail:
                process.env.REACT_APP_API +
                (item.formats.thumbnail !== undefined
                  ? item.formats.thumbnail.url
                  : item.url),
            });
          }
        });
      }
    }

    const isRent =
      typeof this.props.rent !== "undefined" && this.props.rent === true;
    return (
      <Modal
        closeTimeoutMS={500}
        isOpen={this.props.data !== false}
        onRequestClose={this.props.closeModal}
      >
        {this.state.data === false ? null : (
          <div className={isRent ? "ModalNoScroll" : ""} ref={this.refModal}>
            <ImageGallery
              items={images}
              renderLeftNav={(onClick, disabled) => {
                <button
                  className="image-gallery-custom-left-nav"
                  disabled={disabled}
                  onClick={onClick}
                />;
              }}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
            />
            <div className="modalContentPadding">
              <div className="ModalBuildingTitle">
                <div className="ModalBuildingTitleLeft">
                  <h2>{this.state.data.title}</h2>

                  {this.state.data.locality !== null && (
                    <div>
                      <p className="uppercase">{this.props.locales.locality}</p>
                      <p className="bold">{this.state.data.locality}</p>
                    </div>
                  )}
                  {this.state.data.area !== null && (
                    <div>
                      <p className="uppercase">
                        {this.props.locales.pronajimatelnaPlocha}
                      </p>
                      <p className="bold">
                        {this.state.data.area} m<sup>2</sup>
                      </p>
                    </div>
                  )}
                </div>
                <div className="ModalBuildingTitleRight">
                  {this.state.data.development !== null &&
                  this.state.data.development.show === true &&
                  this.state.data.development.logo !== null &&
                  this.state.data.logo === null ? (
                    <img
                      src={
                        process.env.REACT_APP_API +
                        this.state.data.development.logo.url
                      }
                      className="ModalBuildingImage"
                      alt=""
                    />
                  ) : null}
                  {this.state.data.logo !== null ? (
                    <img
                      src={process.env.REACT_APP_API + this.state.data.logo.url}
                      className="ModalBuildingImage"
                      alt=""
                    />
                  ) : null}
                  <div>
                    {this.state.data.webButton !== null && (
                      <a
                        className="ModalButton"
                        target="_blank"
                        rel="noreferrer"
                        href={this.state.data.webButton}
                      >
                        <FontAwesomeIcon
                          icon={faGlobeEurope}
                          className="ModalButtonIcon"
                        />
                        {this.props.locales.webProjektu}
                      </a>
                    )}
                    {this.state.data.socialButton !== null && (
                      <a
                        className="ModalButton"
                        target="_blank"
                        rel="noreferrer"
                        href={this.state.data.socialButton}
                      >
                        <FontAwesomeIcon
                          icon={faShareAlt}
                          className="ModalButtonIcon"
                        />
                        {this.props.locales.socialniSite}
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <Marked value={this.state.data.text} />

              {typeof this.props.broker !== "undefined"
                ? this.props.broker
                : null}
              {typeof this.props.moreRent !== "undefined" &&
              this.props.moreRent.length > 0 ? (
                <div className="ModalBuildingMorerent">
                  {this.props.getBuildingsArray(
                    this.props.moreRent,
                    this.props.buildingId
                  )}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    locales: state.locales,
  };
}

export default connect(mapStateToProps)(ModalBuildings);
