import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./TextAndImage.scss";
import Marked from "../../../Utils/Marked";

class TextAndImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      modal: false,
      modalBuilding: false,
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="TextAndImage maxWidth">
        <div className="TextAndImageTextBox">
          <div className="TextAndImageTitle ">{this.props.title}</div>
          <div className="TextAndImageText">
            <Marked value={this.props.text} />
          </div>
        </div>
        <div className="TextAndImageImageBox">
          <img src={process.env.REACT_APP_API + this.props.image.url} alt="" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(TextAndImage));
