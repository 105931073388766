import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import "./NewsBig.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as Actions from "../../../Redux/Actions";
import markdownToTxt from "markdown-to-txt";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

var dateFormat = require("dateformat");

class NewsBig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tags: [],
    };
  }

  async componentDidMount() {
    this.getTags();
  }

  setLoading = (cb = () => {}) => {
    if (this.state.loading !== true) {
      this.setState(
        {
          loading: true,
        },
        cb
      );
    } else {
      cb();
    }
  };

  getTags = async () => {
    this.setLoading(async () => {
      try {
        const res = await this.props.api.get("tags");
        this.setState({
          tags: res.data,
          loading: false,
        });
      } catch (err) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  render() {
    if (this.state.loading === true) {
      return (
        <div className="fullAndCenter">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      );
    }

    let tags = [];
    this.state.tags.forEach((item, index) => {
      if (item.locale === this.props.locale) {
        tags.push(
          <div
            key={index}
            className={
              this.props.tag.includes(item.id)
                ? "NewsBigCategory NewsBigSelectedCategory"
                : "NewsBigCategory"
            }
            onClick={() => this.props.dispatch(Actions.setTag(item.id))}
          >
            {item.tag}
          </div>
        );
      }
    });

    return (
      <div className="NewsBigContainer maxWidth">
        <div className="NewsBigTitle">{this.props.title}</div>
        <div className="NewsBigContactPersonContainer">
          <div className="maxWidth NewsBigContactPersonContainerWrapper">
            <div className="NewsBigContactPersonTitle">
              {this.props.contactPersonText}
            </div>
          </div>
          <div className="maxWidth NewsBigContactPersonContainerWrapper">
            <div className="NewsBigContactPerson">
              <div className="NewsBigContactPersonContent">
                <div className="NewsBigContactPersonBroker">
                  <div
                    className="NewsBigContactPersonPhoto"
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_API +
                        this.props.contactPersonPhoto.url
                      })`,
                    }}
                  ></div>
                  <div className="NewsBigContactPersonBrokerContact">
                    <div className="bold">{this.props.contactPersonName}</div>
                    <div
                      className="NewsBigContactPersonBrokerEmail"
                      onClick={() => {
                        window.location =
                          "mailto:" + this.props.contactPersonEmail;
                      }}
                    >
                      {this.props.contactPersonEmail}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {tags.length > 0 && (
          <>
            <div className="NewsBigSelectCategory">
              {this.props.selectCategoryText}
            </div>
            <div className="NewsBigSelectCategories">{tags}</div>
          </>
        )}

        <div>
          {this.props.news.data.map((item, index) => {
            const text =
              item.text.length > 290
                ? item.text.slice(0, 290) + "..."
                : item.text;
            return (
              <Link
                className="NewsBigItemWrapper"
                to={
                  this.props.locale === "cs-CZ"
                    ? "/aktuality/" + item.id
                    : "/news/" + item.id
                }
                key={index}
              >
                <div
                  className="NewsBigItemImage"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_API + item.image.url
                    })`,
                  }}
                />
                <div className="NewsBigItemContent">
                  <div className="NewsBigItemTitle">{item.title}</div>
                  <div className="NewsBigItemDate">
                    {dateFormat(item.date, "d.m. yyyy")}
                  </div>
                  <div className="NewsBigItemText">{markdownToTxt(text)}</div>
                </div>
              </Link>
            );
          })}
        </div>
        <div className="NewsBigLoadMoreButtonWrapper">
          <div
            className="NewsBigLoadMoreButton"
            onClick={() => this.props.dispatch(Actions.getNews())}
          >
            <div>{this.props.loadMoreText}</div>
            <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    news: state.news,
    api: state.api,
    locale: state.locale,
    tag: state.tag,
  };
}

export default withRouter(connect(mapStateToProps)(NewsBig));
